<div class="content-wrapper" *ngIf="isAvailable">
  <div class="container" *ngIf="loading">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="
    {
      height: '500px',
      marginTop: '30px'
    }
"></ngx-skeleton-loader>
  </div>

  <div class="container-workspace-education" *ngIf="!loading && course">
    <div class="head-workspace-education container-md">
      <img src="{{course.featured_image_url}}" alt="header"/>
    </div>
    <div class="introduction-to-google container">
      <div class="txt-introduction-to-google">
        <div class="box-text-introduction">
          <h1>{{course.title}}</h1>
          <p>
            {{course.description}}
          </p>
        </div>
      </div>
      <div class="container-lesson">
        <div class="box-progress-lesson flex-bx">
          <div class="box-icon-progress">
            <mat-icon class="donut-icon">donut_large</mat-icon>
            <span class="txt-progress">{{"PROGRESS" | translate}}</span>
            <span class="txt-result">{{course.completion_rate}}%</span>
          </div>
          <div class="box-icon-progress">
            <mat-icon class="format-icon"> format_list_bulleted</mat-icon>
            <span class="txt-progress">{{"UNITS" | translate}}</span>
            <span class="txt-result">{{course.units.length}}</span>
          </div>
          <div class="box-icon-progress">
            <mat-icon class="access-icon">access_time</mat-icon>
            <span class="txt-progress">{{"HOURS" | translate}}</span>
            <span class="txt-result">{{course.estimation}}</span>
          </div>
        </div>
        <div class="box-next-lesson flex-bx">
          <div class="box-options-lesson flex-bx" *ngIf="course.next_module">
            <p class="txt-next-lesson">
              {{
                (course.next_module.type === 'survey' ? 'NEXT_SURVEY' :
                course.next_module.type === 'quiz' ? 'NEXT_QUIZ' :
                'NEXT_LESSON')
                | translate
              }}
            </p>
            <div class="btn-continue">
              <p class="txt-intro-slides">{{course.next_module.title}}</p>
              <a class="btn-arrow-icon" 
                [routerLink]="[
                    course.next_module.type === 'lesson' 
                    ? '/lessons/' + course.next_module.slug + '/' 
                    : '/surveys/' + course.next_module.slug + '/'
                  ]">
                {{"CONTINUE" | translate}}
                <mat-icon>trending_flat</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="loadingUnits">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="
    {
      height: '300px',
      marginTop: '30px'
    }
"></ngx-skeleton-loader>
  </div>

  <div class="container container-units" *ngIf="!loadingUnits">
    <div class="workspace-education-units-title">{{"UNITS" | translate}} ({{units.length}})</div>
    <div class="line-units"></div>
  </div>

  <div class="container" *ngIf="!loadingUnits">
    <ng-container *ngIf="units.length">
      <app-expansion-panel [units]="units"></app-expansion-panel>
    </ng-container>
    <ng-container *ngIf="!units.length">
      <div class="expansion-panel__tab">
        <div class="expansion-panel__card">
          <div class="expansion-panel__title">
            <div class="expansion-panel__title-text">{{"NO_UNITS" | translate}}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="content-wrapper" *ngIf="!isAvailable">
  <div class="container-workspace-education">
    <div class="introduction-to-google container" style="top: 0 !important;">
      <div class="txt-introduction-to-google">
        <div class="box-text-introduction">
          <h1>{{"COURSE_NOT_FOUND" | translate}}</h1>
          <p>
            {{"COURSE_NOT_FOUND_DESCRIPTION" | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>

</div>
