import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Block, Lesson } from '../../types/models';
import { CoreService } from '../../services/core.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UnionService } from '../../services/union.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lesson',
  templateUrl: './lesson.component.html',
  styleUrls: ['./lesson.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LessonComponent implements OnInit {
  public lesson: Lesson | undefined;
  public loading = true;
  public blocks: Block[] = [];
  public nextControl: any;
  public prevControl: any;

  constructor(
    private coreService: CoreService,
    private activatedRoute: ActivatedRoute,
    private unionService: UnionService,
    private title: Title,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    /* Load lessons */
    this.activatedRoute.params.subscribe((params) => {
      this.loading = true;
      const slug: string | null = params['slug'];
      if (slug) {
        this.loadLesson(slug);
      }
    });

    /* Go to course overview if language is change */
    this.unionService.languageChange.subscribe(() => {
      this.router.navigate(['/courses/' + this.lesson?.unit?.course]);
    });
  }

  /* Loads the lesson data */
  public loadLesson(slug: string): void {
    this.coreService.getLesson(slug).subscribe(
      (lesson) => {
        this.lesson = lesson;
        setTimeout(() => {
          this.setNavigationControls(lesson);
          this.title.setTitle(`${lesson.title} - Google Workspace Training`);
          this.loading = false;
          document.getElementById('drawer')?.scrollIntoView();
        }, 0);
      },
      () => {
        this.loading = false;
        document.getElementById('drawer')?.scrollIntoView();
      }
    );
  }

  /* Scroll to section */
  public scrollTo(section: string): void {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /* Set navigation controls */
  public setNavigationControls(lesson): void {
    if(lesson.next_unit_type === "lesson"){
      this.nextControl = {
        slug: lesson.next_lesson.slug,
        title: lesson.next_lesson.title,
        label: this.translate.instant('NEXT_LESSON'),
        url: `${lesson.next_lesson_url}`,
        buttonText: this.translate.instant('CONTINUE'),
      };
    }
    else if (lesson.next_unit_type === "survey") {
      this.nextControl = {
        slug: lesson.next_questionnaire.slug,
        title: lesson.next_questionnaire.title,
        label: this.translate.instant('NEXT_SURVEY'),
        url: `${lesson.next_lesson_url}`,
        buttonText: this.translate.instant('CONTINUE'),
      };
    }


    
    this.prevControl = {
      slug: lesson.previous_lesson.slug,
      title: this.translate.instant('COURSE_OVERVIEW'),
      label: this.translate.instant('GO_BACK'),
      url: `/courses/${lesson.course}`,
      buttonText: this.translate.instant('CONTINUE'),
    };
  }
}
